import React, { useEffect, useState } from "react";
import {
  updateMarketAssets,
  getStakeKey,
  stakeFromUsername,
  mergeMarket,
} from "../../api";
import "./index.css";
import dashSVG from "../../assets/images/dash.svg";
import nftSVG from "../../assets/images/nfts.svg";
import Market from "./Market.jsx";
import SingleAssetUpdate from "./SingleAssetUpdate.jsx";

export default function FreeroamFixer() {
  const [stakeAddress, setStakeAddress] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [assetId, setAssetId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [successMerge, setSuccessMerge] = useState(false);

  //DISPLAYS
  const [marketAssets, setMarketAssets] = useState("");

  const [displayTab, setDisplayTab] = useState("MARKET");

  const fetchMarketInfo = async () => {
    setIsLoading(true);
    updateMarketAssets(stakeAddress).then((res) => {
      setMarketAssets(res)
      setIsLoading(false);
    });
  };

  const resetFields = (shouldReset) => {
    if (shouldReset) {
      setMarketAssets("");
      setStakeAddress("");
      setWalletAddress("");
    }
  };

  // const submitMerge = async () => {
  //   mergeMarket(walletUpdate, stakeAddress).then((res) => {
  //     setSuccessMerge(res);
  //     resetFields(res);
  //     setInterval(() => {
  //       setSuccessMerge(false);
  //     }, 5000);
  //   });
  // };

  const fetchStakeFromUsername = async () => {
    setStakeAddress("");
    stakeFromUsername(walletAddress).then((stake) => {
      setStakeAddress(stake);
    });
  };

  const getStakeFromAddress = () => {
    setStakeAddress("");
    getStakeKey(walletAddress).then((stake) => {
      setStakeAddress(stake);
    });
  };

  const TABS = {
    MARKET: {
      label: "MARKET UPDATE",
      icon: <img src={dashSVG} style={{ width: "24px", height: "24px" }} />,
    },
    SINGLE: {
      label: "SINGLE ASSET UPDATE",
      icon: <img src={nftSVG} style={{ width: "24px", height: "24px" }} />,
    },
  };

  return (
    <>
      <h2>Market Fixer</h2>
      <section className="section dash-section">
        {/* ADD BACK FOR MAINNET */}
        <Market
          setWalletAddress={setWalletAddress}
          walletAddress={walletAddress}
          stakeAddress={stakeAddress}
          setStakeAddress={setStakeAddress}
          setAssetId={setAssetId}
          assetId={assetId}
          isLoading={isLoading}
          fetchStakeFromUsername={fetchStakeFromUsername}
          fetchMarketInfo={fetchMarketInfo}
          successMerge={successMerge}
          getStakeFromAddress={getStakeFromAddress}
          marketAssets={marketAssets}
        />
      </section>
    </>
  );
}
