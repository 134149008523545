import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
    const { loginWithRedirect, isAuthenticated } = useAuth0();

    return (
        <div
            style={{
                minHeight: "100vh",
                display:"grid",
                placeContent:"center"
            }}
        >
            {!isAuthenticated && (
                <button onClick={() => loginWithRedirect()}>Log In</button>
            )}
        </div>
    );
};

export default LoginButton;
