import React from "react";
import ReactJsonViewCompare from "react-json-view-compare";

export default function Market({
  setWalletAddress,
  walletAddress,
  stakeAddress,
  setStakeAddress,
  setAssetId,
  assetId,
  assetIdsMatch,
  isLoading,
  marketAssets,
  fetchStakeFromUsername,
  fetchMarketInfo,
  oldWalletInfo,
  walletUpdate,
  successMerge,
  submitMerge,
  getStakeFromAddress
}) {
  return (
    <div>
      <br />
      <br />
      {/* TODO: fix current wallet issues, also improve how it selects the "submittedBy" address. Seems weak. */}
      {/* Stake Address from username or addr */}
      <div>
        <h3>Fetch Stake Address here from wallet or username:</h3>
        <input
          placeholder="Enter wallet address here. E.g. addr...47h"
          value={walletAddress}
          onChange={(e) => setWalletAddress(e.target.value)}
        />
        <br />
        {stakeAddress && stakeAddress}
        <br />
        <button
          onClick={
            walletAddress?.length < 56
              ? fetchStakeFromUsername
              : getStakeFromAddress
          }
        >
          Get Stake
        </button>
      </div>

      <br />
      <br />

      {/* Enter wallet info here */}
      <div>
        <input
          placeholder="Enter stake address here. E.g. stake...47h"
          value={stakeAddress}
          onChange={(e) => setStakeAddress(e.target.value)}
        />
        <input
          placeholder="Enter asset id here if updating an asset. Else leave it blank."
          value={assetId}
          onChange={(e) => setAssetId(e.target.value)}
        />
        <button onClick={fetchMarketInfo}>Get Market</button>
      </div>

      <hr />
      <br />
      <div class="row">
        <p>Majority of information should be the same</p>
        {isLoading && (
          <p style={{ fontSize: "18px", fontWeight: "bold", color: "red" }}>
            Loading...
          </p>
        )}
        <br />

        {/* <ReactJsonViewCompare oldData={oldWalletInfo} newData={walletUpdate} /> */}
        {/* display as a list of each market reference that is listed. Then highligh in red if not in contract. 
        
          if yes in contract: 
                    Highlight in green.
                    and make sure locked is true in assets collection.

          if not in contract: 
                    Highligh in red
                    remove from market reference.
        
        */}

        {Object.keys(marketAssets).length > 0 && Object.keys(marketAssets).map((a) => {
          console.log(marketAssets[a]["isLocked"])
          return (
            <div>{a} - {marketAssets[a]["isLocked"].toString()}</div>
          )
        })}


      </div>

      {successMerge && "Successfully Fixed"}
      <button
        onClick={submitMerge}
        style={{
          background: "#00ffc240",
          border: "1px solid #00ffc2",
          borderRadius: "8px",
          padding: "8px",
        }}
      >
        Merge Update for Wallet
      </button>
    </div>
  );
}
