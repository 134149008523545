import axios from "axios";
import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";

const TableEditableColumn = ({
    value,
    name,
    num,
    loadRecords,
    name_id
}) => {
    const [isEditting, setIsEditing] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const inputRef = useRef(null);

    const handleEditSubmit = async (e) => {
        e.preventDefault();

        try {
            const res = await axios.put(
                "https://api.freeroam.io/calendar",
                { [name]: inputValue, name_id },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            setIsEditing(false);
            setInputValue("");
            loadRecords();
        } catch (error) {
            setInputValue(value);
            setIsEditing(false);
        }
    };

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    useEffect(() => {
        if (inputRef.current) {
            document.addEventListener("click", (e) => {
                if (!inputRef?.current?.contains(e.target)) {
                    setIsEditing(false);
                }
                console.log(inputRef.current);
            });
        }

        return () => {
            // document.removeEventListener("click", () => {});
        };
    }, []);

    return (
        <div>
            {/* {isEditting ? (
                <form onSubmit={handleEditSubmit}>
                    <input
                        ref={inputRef}
                        type={num ? "number" : "text"}
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                    />
                </form>
            ) :  */}
            {/* ( */}
                <p onClick={() => setIsEditing(true)}>{value || "NB"}</p>
            {/* )} */}
        </div>
    );
};

export default TableEditableColumn;
