import React from "react";
import "./App.css";
import { useAuth0 } from "@auth0/auth0-react";
import { Route, Routes } from "react-router-dom";

import HomePage from "./pages/Home";
import CalendarListingPage from "./pages/CalendarListing";
import FreeroamFixer from "./pages/FreeroamFixer";
import AddColectionPage from "./pages/AddColection";
import Header from "./components/Header";
import LoginButton from "./components/LoginButton";
import Royalty from "./pages/Royalty";

function App() {
    const { isLoading, isAuthenticated } = useAuth0();

    if (isLoading) return <div>Loading...</div>;

    if (!isAuthenticated) {
        return <LoginButton />;
    }

    return (
        <div>
            <Header />
            <div className="container">
                <Routes>
                    <Route exact path="/" element={<HomePage />} />
                    <Route
                        exact
                        path="/collections"
                        element={<CalendarListingPage />}
                    />
                    <Route
                        exact
                        path="/collections/new"
                        element={<AddColectionPage />}
                    />
                    <Route
                        exact
                        path="/freeroam"
                        element={<FreeroamFixer />}
                    />
                    <Route
                        exact
                        path="/royalty"
                        element={<Royalty />}
                    />
                </Routes>
            </div>
        </div>
    );
}

export default App;
