import { first } from "lodash";
import React, { useState, useEffect } from "react";
import { getProjectName, getCIP, mergeRoyalties } from "../../api";
import "./index.css";

export default function Royalty() {
  const [policyId, setPolicyId] = useState("");
  const [projectName, setProjectName] = useState("");

  //error ux
  const [noneFound, setNoneFound] = useState(false);
  const [didMerge, setDidMerge] = useState("waiting");

  const [rawCIP, setRawCIP] = useState("");
  const [addrCIP, setAddrCIP] = useState("");
  const [percentCIP, setPercentCIP] = useState("");

  const resetResults = () => {
    console.log("reseting");
    setRawCIP("");
    setAddrCIP("");
    setPercentCIP("");
    setNoneFound(false);
    setProjectName("");
    setPolicyId("");
    setDidMerge("waiting");
  };

  const getRoyaltyToken = async () => {
    let project = await getProjectName(policyId);
    let firstCIP = await getCIP(policyId);
    let percent;

    if (project && typeof project !== "object") {
      setProjectName(project);
    } else {
      setProjectName("Unnamed Project on FreeRoam");
    }

    if (firstCIP) {
      let joinedAddress = "";
      console.log(firstCIP.addr, "HERE");
      setAddrCIP(firstCIP.addr.join(",").replace(",", ""));
      percent = firstCIP.pct ? firstCIP.pct : firstCIP.rate;
      setPercentCIP(percent);
      setRawCIP(firstCIP);
    } else {
      setNoneFound(true);
    }
  };

  const mergeRoyaltyToken = async () => {
    mergeRoyalties(policyId, addrCIP, percentCIP).then((response) => {
      console.log(response, "did merge?");
      setDidMerge(response);
      resetResults()
    });
  };

  useEffect(() => {
    console.log(addrCIP, percentCIP, "here");
  }, []);

  return (
    <div>
      <p>
        <em>
          ** update royalty only with api key - should be locked collection to
          prevent dynamic changes or hax **
        </em>
        <br />
        <br />
        <strong>
          This tool looks for a collection displayName in our database. Returns
          the name if found with the CIP token information. If the CIP token was
          not minted in the first 10 transactions of the policyId we have to
          take a more hands on approach to finding and verifying information.
        </strong>
      </p>
      <br />
      <div style={{ backgroundColor: "#00ffc280", marginTop: "16px" }}>
        <br />
        <p style={{ fontWeight: "bold" }}>
          Find CIP on chain and add royalties to collection. (Preferred Method)
        </p>
        <br />
        <input
          placeholder="Enter policyId (get from freeroam.io)"
          value={policyId}
          onChange={(e) => setPolicyId(e.target.value)}
        />
        {noneFound && (
          <p style={{ fontSize: "18px", fontWeight: "bold", color: "red" }}>
            NO CIP TOKEN FOUND - MANUAL ADDITION REQUIRED
          </p>
        )}
        <button onClick={getRoyaltyToken}>Get Royalty Information</button>
      </div>
      {projectName && (
        <div>
          <div style={{ backgroundColor: "lightgrey", marginTop: "16px" }}>
            <hr />
            <br />
            <p style={{ fontWeight: "bold" }}>
              Manual addition if no CIP token found. (Not preferred)
            </p>
            <br />
            <input
              placeholder="Enter PolicyId"
              value={policyId}
              onChange={(e) => setPolicyId(e.target.value)}
            />
            <br />
            <input
              placeholder="Enter Royalties Address"
              value={addrCIP}
              onChange={(e) => setAddrCIP(e.target.value)}
            />
            <br />
            <input
              placeholder="Enter Percentage in decimal format. E.g. 5% = 0.05"
              value={percentCIP}
              onChange={(e) => setPercentCIP(e.target.value)}
            />
            <br />
            <hr />
          </div>
          <br />
          <br />
          <p className="headers">
            <span style={{ color: "green", fontWeight: "bold" }}>
              {projectName}
            </span>{" "}
            Royalties
          </p>
          <br />
          <p className="headers">Address</p>
          <p>{addrCIP}</p>
          <br />
          <p className="headers">Royalty</p>
          <p>{(Number(percentCIP) * 100).toFixed(0)}% per sale.</p>
          <p>{Number(percentCIP) * 1000} adjusted for database. (x10)</p>
          <br />
          <p className="headers">Raw info:</p>
          <pre
            style={{
              backgroundColor: "lightgrey",
              padding: "16px",
              borderRadius: "5px",
            }}
          >
            {JSON.stringify(rawCIP, null, 2)}
          </pre>

          <button
            onClick={mergeRoyaltyToken}
            style={{
              backgroundColor: "#00ffc240",
              borderRadius: "5px",
              border: "1px solid #00ffc2",
            }}
          >
            Add Royalty
          </button>
          {didMerge != "waiting" && <p>Merge Success? : {didMerge}</p>}
        </div>
      )}
    </div>

    // list a all the projects with royalties, name and address.

    // list all recently changed royalty addresses?
  );
}
