import { apiKey, cardanoUrl } from "../config/config";
import { errorTypes } from "./error.types";
const axios = require("axios");

const fromHex = (str1) => {
  var hex = str1.toString();
  var str = "";
  for (var n = 0; n < hex.length; n += 2) {
    str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
  }
  return str;
};

const cardano = async (endpoint, headers, body) => {
  return await request(cardanoUrl, endpoint, headers, body);
};

const renameObjectKey = (object, oldKey, newKey) => {
  let newObject = { ...object };
  if (oldKey in newObject) {
    newObject[newKey] = newObject[oldKey];
    delete newObject[oldKey];
  }
  return newObject;
};

const deleteObjectKey = (object, key) => {
  let newObject = {};
  if (object) {
    Object.keys(object).forEach((objectKey) => {
      if (typeof object[objectKey] === "object") {
        newObject = {
          ...newObject,
          [objectKey]: deleteObjectKey(object[objectKey], key),
        };
      } else if (objectKey !== key) {
        newObject = {
          ...newObject,
          [objectKey]: object[objectKey],
        };
      }
    });
  }
  return newObject;
};

const request = async (base, endpoint, headers, body) => {
  console.log(base + endpoint);
  return await axios(base + endpoint, {
    headers: {
      project_id: apiKey,
      ...headers,
    },
    method: body ? "POST" : "GET",
    body,
  }).then((response) => {
    if (response.status != 200) {
      throw new Error(response.status);
    }
    console.log(response)
    return response.data;
  });
};

export const getAssetAddresses = async (asset) => {
  try {
    let addresses = await cardano(`assets/${asset}/addresses`);
    return addresses[0].address;
  } catch (error) {
    console.error(
      `Unexpected error in getAssetAddresses. [Message: ${error.message}]`
    );
    throw new Error(errorTypes.COULD_NOT_FETCH_ASSET_TRANSACTIONS);
  }
};

export const getAssetDatum = async (txHash) => {
  try {
    let datum = await axios.get(
      `https://cardano-mainnet.blockfrost.io/api/v0/txs/${txHash}/metadata`,
      {
        headers: {
          project_id: "mainnetRmVFHw53WOJ8WjvUz3ivalDsXpJhXeg8",
        },
      }
    );
    datum = datum.data; 

    if(datum.length > 0){
      return datum[0].json_metadata;
    } else {
      return false;
    }

  } catch (error) {
    console.error(
      `Unexpected error in getAssetDatum. [Message: ${error.message}]`
    );
    throw new Error(errorTypes.COULD_NOT_FETCH_ASSET_TRANSACTIONS);
  }
};

export const getUTxOs = async (txHash) => {
  try {
    let utxos = await axios.get(
      `https://cardano-mainnet.blockfrost.io/api/v0/txs/${txHash}/utxos`,
      {
        headers: {
          project_id: "mainnetRmVFHw53WOJ8WjvUz3ivalDsXpJhXeg8",
        },
      }
    );
    utxos = utxos.data;

    return utxos;
  } catch (error) {
    console.error(`Unexpected error in getUTxOs. [Message: ${error.message}]`);
    throw new Error(errorTypes.COULD_NOT_FETCH_ASSET_TRANSACTIONS);
  }
};

export const getAssetDetails = async (asset) => {
  try {
    let response = await axios.get(
      `https://cardano-mainnet.blockfrost.io/api/v0/assets/${asset}`,
      {
        headers: {
          project_id: "mainnetRmVFHw53WOJ8WjvUz3ivalDsXpJhXeg8",
        },
      }
    );
    console.log(response);
    response = response.data;

    if (parseInt(response.quantity) === 1 && response.onchain_metadata) {
      const assetDetails = {
        asset: response.asset,
        policyId: response.policy_id,
        assetName: response.asset_name,
        readableAssetName: fromHex(response.asset_name),
        fingerprint: response.fingerprint,
        quantity: parseInt(response.quantity),
        initialMintTxHash: response.initial_mint_tx_hash,
        mintOrBurnCount: parseInt(response.mint_or_burn_count),
        onchainMetadata: renameObjectKey(
          response.onchain_metadata,
          "Name",
          "name"
        ),
        metadata: response.metadata,
      };
      return deleteObjectKey(assetDetails, "");
    }

    return undefined;
  } catch (error) {
    console.error(
      `Unexpected error in getAssetDetails. [Message: ${error.message}]`
    );
    throw new Error(errorTypes.COULD_NOT_FETCH_ASSET_DETAILS);
  }
};

/**
 * @throws COULD_NOT_FETCH_ASSET_TRANSACTIONS
 */
export const getAssetTxHash = async (asset) => {
  try {
    let txs = await axios.get(
      `https://cardano-mainnet.blockfrost.io/api/v0/assets/${asset}/transactions?page=1&count=1&order=desc`,
      {
        headers: {
          project_id: "mainnetRmVFHw53WOJ8WjvUz3ivalDsXpJhXeg8",
        },
      }
    );

    txs = txs.data;

    return txs;
  } catch (error) {
    console.error(
      `Unexpected error in getAssetTxHash. [Message: ${error.message}]`
    );
    throw new Error(errorTypes.COULD_NOT_FETCH_ASSET_TRANSACTIONS);
  }
};

/**
 * @throws COULD_NOT_FETCH_ASSET_TRANSACTIONS
 */
export const getStakeAddress = async (address) => {
  try {
    let stake = await axios.get(
      `https://cardano-mainnet.blockfrost.io/api/v0/addresses/${address}`,
      {
        headers: {
          project_id: "mainnetRmVFHw53WOJ8WjvUz3ivalDsXpJhXeg8",
        },
      }
    );

    stake = stake.data.stake_address;

    return stake;
  } catch (error) {
    console.error(
      `Unexpected error in getStakeAddress. [Message: ${error.message}]`
    );
    throw new Error(errorTypes.COULD_NOT_FETCH_ASSET_TRANSACTIONS);
  }
};

// Should find in the first 10 ttransactions the CIP token.
export const getFirstPolicyMints = async (policyId) => {
  try {
    let tokens = await axios.get(
      `https://cardano-mainnet.blockfrost.io/api/v0/assets/policy/${policyId}?count=10`,
      {
        headers: {
          project_id: "mainnetRmVFHw53WOJ8WjvUz3ivalDsXpJhXeg8",
        },
      }
    );

    tokens = tokens.data;

    return tokens;
  } catch (error) {
    console.error(
      `Unexpected error in getStakeAddress. [Message: ${error.message}]`
    );
    throw new Error(errorTypes.COULD_NOT_FETCH_ASSET_TRANSACTIONS);
  }
};

export const getAssetTxs = async (assetId) => {
  try {
    let txs = await axios.get(
      `https://cardano-mainnet.blockfrost.io/api/v0/assets/${assetId}/transactions`,
      {
        headers: {
          project_id: "mainnetRmVFHw53WOJ8WjvUz3ivalDsXpJhXeg8",
        },
      }
    );

    txs = txs.data;

    return txs;
  } catch (error) {
    console.error(
      `Unexpected error in getAssetTxs. [Message: ${error.message}]`
    );
    console.log(error);
  }
};
