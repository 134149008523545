import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "./style.css";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";

const axios = require("axios");

export default function CalendarForm() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [isSending, setSending] = useState(false);
    const [text, setText] = useState("");

    const navigate = useNavigate();

    const onSubmit = (data) => {
        const formData = new FormData();
        for (let key of Object.keys(data)) {
            if(key==="logo_image" || key==="banner_image" || key==="card_image"){
                console.log(data[key][0]);
                formData.append(key, data[key][0]);
            }else{
                formData.append(key, data[key]);
            }
        }

        setSending(true);
        axios
            .post(
                "https://api.freeroam.io/calendar",
                formData
            )
            .then(function (response) {
                setSending(false);
                alert("Collection added successfully");
                navigate("/collections");
                console.log(response, "GOOD");
            })
            .catch(function (response) {
                setSending(false);
                //handle error
                console.log(response, "BAD");
            });
    };

    const handleChange = (value) => {
        setText(value);
    };

    useEffect(() => {
        document.getElementsByClassName("date").flatpickr({
            enableTime: true,
            dateFormat: "F d, Y H:i",
        });
    }, []);

    return (
        <>
            <div style={{ marginBottom: "32px" }}>
                <h1 style={{ textAlign: "center" }}>
                    Add collection to the calendar, * means Required.
                </h1>
            </div>

            <form
                className="add-collection-form"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="flex">
                    <div className="form-group">
                        <label className="input-label">
                            Collection Display Name{" "}
                            <span className="red-dot">*</span> <br />
                            (must be unique)
                        </label>
                        <input
                            type="text"
                            placeholder="Collection Display Name"
                            {...register("display_name", {
                                required: true,
                                maxLength: 100,
                            })}
                        />
                    </div>
                    <div className="form-group">
                        <label className="input-label">
                            Collection Policy ID <br />
                            (if found)
                        </label>
                        <input
                            type="text"
                            placeholder="PolicyId"
                            {...register("policy_id", { required: false })}
                        />
                    </div>
                </div>
                <div className="flex">
                    <div className="form-group">
                        <label className="input-label">
                            NFT Total
                            <br />
                            (if specified)
                        </label>
                        <input
                            type="text"
                            placeholder="NFT Total"
                            {...register("nft_total", { required: false })}
                        />
                    </div>
                    <div className="form-group">
                        <label className="input-label">
                            Collection Hexcode <br /> (e.g. #000000 is Black)
                        </label>
                        <input
                            placeholder="hexcode"
                            {...register("hexcode", {
                                required: false,
                                maxLength: 9,
                            })}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label className="input-label">
                        Collection Categories (Select up to 3)
                    </label>
                    <select
                        multiple
                        {...register("categories", { required: false })}
                        style={{ height: "200px" }}
                    >
                        <option value="category_artistic">Artistic</option>
                        <option value="category_collectables">
                            Collectible
                        </option>
                        <option value="category_pfp">
                            PFP (Profile Picture)
                        </option>
                        <option value="category_utility">Utility</option>
                        <option value="category_ai">A.I</option>
                        <option value="category_game">Game</option>
                        <option value="category_p2e">P2E (Play to Earn)</option>
                        <option value="category_virtual_worlds">
                            Virtual Worlds
                        </option>
                        <option value="category_pixel_retro">
                            Pixel/Retro
                        </option>
                        <option value="category_domain">Domain</option>
                        <option value="category_music">Music</option>
                        <option value="category_dao">DAO</option>
                    </select>
                </div>
                <div className="flex">
                    <div className="form-group">
                        <label className="input-label">
                            Collection Mint Status *
                        </label>
                        <select
                            {...register("mint_status", { required: true })}
                        >
                            <option value="fully_minted">Fully Minted</option>
                            <option value="currently_minting">
                                Currently Minting
                            </option>
                            <option value="mint_not_started">
                                Minting Hasn't Started
                            </option>
                            <option value="continuous_mint">
                                Continuous Mint
                            </option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label className="input-label">
                            Collection Mint Date & Time (UTC){" "}
                            <span className="dot-red">*</span>
                        </label>
                        <input
                            className="date"
                            name="mint_date_time"
                            id="mint_date_time"
                            type="date"
                            placeholder="Click Me"
                            {...register("mint_date_time", { required: true })}
                        />
                    </div>
                </div>
                <div className="flex">
                    <div className="form-group">
                        <label className="input-label">
                            Collection Website
                        </label>
                        <input
                            type="text"
                            placeholder="website"
                            {...register("website", { required: false })}
                        />
                    </div>
                    <div className="form-group">
                        <label className="input-label">
                            Collection Twitter
                        </label>
                        <input
                            type="text"
                            placeholder="twitter"
                            {...register("twitter", { required: false })}
                        />
                    </div>
                </div>
                <div className="flex">
                    <div className="form-group">
                        <label className="input-label">
                            Collection Discord
                        </label>
                        <input
                            type="text"
                            placeholder="discord"
                            {...register("discord", { required: false })}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label className="input-label">
                        Collection Description (Short plz, less than 256 Char.
                        20 Words) *
                    </label>
                    <textarea
                        type="description"
                        rows={6}
                        placeholder="description"
                        {...register("description", { required: true })}
                    ></textarea>
                </div>
                <div className="flex">
                    <div className="form-group">
                        <label className="input-label">Logo Image *</label>
                        <input
                            type="file"
                            accept="image/*"
                            id="logo_image"
                            name="logo_image"
                            {...register("logo_image", { required: true })}
                        />
                    </div>
                    <div className="form-group">
                        <label className="input-label">Banner Image *</label>
                        <input
                            type="file"
                            accept="image/*"
                            id="banner_image"
                            name="banner_image"
                            {...register("banner_image", { required: true })}
                        />
                    </div>
                    <div className="form-group">
                        <label className="input-label">Card Image *</label>
                        <input
                            type="file"
                            accept="image/*"
                            id="card_image"
                            name="card_image"
                            {...register("card_image", { required: true })}
                        />
                    </div>
                </div>
                <input
                    disabled={isSending}
                    className="submit-btn"
                    type="submit"
                />
            </form>

            {isSending && (
                <div>
                    <p
                        style={{ textAlign: "center", margin: "20px" }}
                        id="response-text"
                    >
                        Waiting Response
                    </p>
                </div>
            )}
        </>
    );
}
