/* development */
export const apiKey = process.env.REACT_APP_BLOCKFROST_API_KEY;
export const cardanoUrl = process.env.REACT_APP_BLOCKFROST_API_URL;
export const baseApiUrl = "https://freeroam-testnet.ew.r.appspot.com/api";

export const urls = {
  root: "https://freeroam-mkp-marty.web.app/",
  cardanoscan: process.env.REACT_APP_CARDANOSCAN_URL,
  ipfs: "https://ipfs.io/ipfs/"
};
