import React, { useState } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useEffect } from "react";
import axios from "axios";
import TableEditableColumn from "../../components/TableEditableColumn";
import TableEditableColumnSelect from "../../components/TableEditableColumnSelect";
import { baseApiUrl } from "../../config/config";

import "./calendarlisting.css";

const CalendarListingPage = () => {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteCollection = async (name_id, policyId) => {
    try {
      let confirmed = window.confirm(
        "Are you sure you want to delete " + name_id + "?"
      );
      if (confirmed) {
        // setIsDeleting(true);
        // const res = await axios.delete("https://api.freeroam.io/calendar", {
        //   data: { name_id },
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        // });
        // setIsDeleting(false);
        loadRecords();
      } else {
        return;
      }
    } catch (error) {
      setIsDeleting(false);
      console.log({ error });
    }
  };

  const addAward = async (type, policyId, bool) => {
    let patchObj = {};
    patchObj[type] = bool;

    console.log(patchObj, `${baseApiUrl}/collections/${policyId}`);

    let { data } = await axios({
      method: "patch",
      url: `${baseApiUrl}/collections/${policyId}`,
      data: patchObj,
    });

    if (data === "response") {
      console.log(data, policyId, " <- UPDATED");
    } else {
      alert(
        `Error - something went wrong trying to update. Here is some info: \n\n ${
          (type, policyId, bool)
        }`
      );
    }
  };

  const loadRecords = async () => {
    setLoading(true);
    const { data } = await axios.get(`${baseApiUrl}/collections`);
    setRecords(data);
    setLoading(false);
  };

  const columns = [
    {
      name: "Name ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Display Name",
      selector: (row) => (
        <TableEditableColumn
          value={row.displayName}
          name_id={row.name_id}
          name="display_name"
          loadRecords={loadRecords}
        />
      ),
    },
    {
      name: "isVerified",
      selector: (row) => (
        <div style={{ display: "flex", gap: "20px", cursor: "pointer" }}>
          <p
            className={row.isVerified && "verified"}
            id={`${row.policyId}-verify`}
            onClick={() => {
              if (!row.isVerified) {
                document
                  .getElementById(`${row.policyId}-verify`)
                  .classList.add("verified"); //add verified

                document.getElementById(`${row.policyId}-verify`).innerHTML =
                  "Verified";
              } else {
                document
                  .getElementById(`${row.policyId}-verify`)
                  .classList.remove("verified"); //remove
              }
              addAward("isVerified", row.policyId, !row.isVerified);
            }}
          >
            {!row.isVerified ? "Verify" : "Verified"}
          </p>
        </div>
      ),
    },
    {
      name: "isOriginal",
      selector: (row) => (
        <div style={{ display: "flex", gap: "20px", cursor: "pointer" }}>
          <p
            className={row.isOriginal && "original"}
            onClick={() => {
              if (!row.isVerified) {
                document
                  .getElementById(`${row.policyId}-original`)
                  .classList.add("original"); //add verified

                document.getElementById(`${row.policyId}-original`).innerHTML =
                  "Original";
              } else {
                document
                  .getElementById(`${row.policyId}-original`)
                  .classList.remove("original"); //remove
              }
              addAward("isOriginal", row.policyId, !row.isOriginal);
            }}
          >
            {!row.isOriginal ? "Not Original" : "Original"}
          </p>
        </div>
      ),
    },
    {
      name: "Policy ID",
      selector: (row) => (
        <TableEditableColumn
          value={row.policyId}
          name_id={row.name_id}
          name="policy_id"
          loadRecords={loadRecords}
        />
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <div style={{ display: "flex", gap: "20px", cursor: "pointer" }}>
          <p
            className="delete-hoverable"
            onClick={() => deleteCollection(row.name_id, row.policyId)}
          >
            Delete
          </p>
        </div>
      ),
    },
  ];

  useEffect(() => {
    loadRecords();
  }, []);

  return (
    <div>
      <em>
        <div>
          <span style={{fontWeight: "bold", color: "#00ffc2"}}>Verified</span> = is above 250k ADA trade volume and reasonable wallet
          distribution. Art cannot be an EXACT copy of other artwork on Cardano or another chain. Subject to review. 
        </div>
        <br />
        <div>
        <span style={{fontWeight: "bold", color: "#ccacfe"}}>Original</span> = internal review process. Eventually extending votes into
          the Ghost Senate.
        </div>
        <br />
        <br />
      </em>
      {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Link className="new-collection-btn" to="/collections/new">
          New Collection
        </Link>
      </div> */}
      {isDeleting && <p style={{ textAlign: "center" }}>Loading...</p>}
      <div style={{ margin: "20px 0px" }}>
        {loading && <p style={{ textAlign: "center" }}>Loading...</p>}
        <DataTable columns={columns} data={records} />
      </div>
    </div>
  );
};

export default CalendarListingPage;
