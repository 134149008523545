import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  startAfter,
  where,
  updateDoc,
  increment,
  arrayUnion,
  arrayRemove,
  collectionGroup,
} from "firebase/firestore";
import { firestore } from "../firebase";
import {
  getAssetAddresses,
  getAssetDetails,
  getAssetTxHash,
  getUTxOs,
  getAssetDatum,
  getAssetTxs,
  getStakeAddress,
  getFirstPolicyMints,
} from "../utils/blockfrost";
import { arraysAreEqual } from "../utils/utils";

const axios = require("axios");
const _ = require("lodash");

const contractAddress =
  "addr1wxkqxmfkt6jas8mul0luqea8c5vsg8reu3ak3v9cswmm6yg2u9mrh";
let shouldUpdateStatus;
let shouldUpdateDetails;

const selectors = async () => {
  console.log("whapsk");
};

// GET STAKEADDRESS FROM USERNAME
export const getStakeAddressFromUsername = async (userName) => {
  try {
    const reference = query(
      collection(firestore, "wallets"),
      where("userName", "==", userName)
    );
    const snapshot = await getDoc(reference);

    if (snapshot.exists()) {
      return snapshot.data();
    }
  } catch (err) {
    console.log(err);
  }
};

const getMarketDocs = async (stakeAddress) => {
  const reference = collection(firestore, "wallets", stakeAddress, "market");
  const docs = await getDocs(reference);
  return docs.docs.map((doc) => doc.data().reference.path);
};

const setAssetLockedTrue = async (assetId) => {
  const reference = doc(firestore, "assets", assetId);
  let collectionResponse = await getCollection(assetId.substring(0, 56));
  await setDoc(
    reference,
    {
      collection: collectionResponse,
      status: {
        locked: true,
      },
    },
    { merge: true }
  );
  return true;
};

const setAssetLockedFalse = async (assetId) => {
  const reference = doc(firestore, "assets", assetId);
  await setDoc(reference, { status: { locked: false } }, { merge: true });
  return true;
};

const getCollection = async (policyId) => {
  const reference = doc(firestore, "collections", policyId);
  const snapshot = await getDoc(reference);
  if (snapshot.exists()) {
    return snapshot.data();
  } else {
    return {
      policy_id: policyId,
    };
  }
};

export const getCIP = async (policyId) => {
  let firstTokens = await getFirstPolicyMints(policyId);
  let infoCIP;
  let CIP;

  for (let i = 0; i < firstTokens.length; i++) {
    if (firstTokens[i].asset === policyId) {
      // found a nameless CIP token so get transaction info of asset
      infoCIP = await getAssetTxs(firstTokens[i].asset);
      // then you get txHash of minted token
      CIP = await getAssetDatum(infoCIP[0].tx_hash);
    }
  }

  return CIP;
};

export const getProjectName = async (policyId) => {
  const reference = doc(firestore, "collections", policyId);
  const snapshot = await getDoc(reference);
  if (snapshot.exists()) {
    let data = snapshot.data();
    return data.displayName;
  } else {
    return {
      policy_id: policyId,
    };
  }
};

export const stakeFromUsername = async (username) => {
  const reference = await query(
    collection(firestore, "wallets"),
    where("userName", "==", username)
  );
  const snapshot = await getDocs(reference);
  if (snapshot.empty) {
    return {
      policy_id: false,
    };
  } else {
    let data = snapshot.docs.map((doc) => doc.data());
    return data[0].stakeAddress;
  }
};

const checkUTxOsContract = async (utxos, assetId) => {
  let inContract = utxos.outputs.some(
    (output) => output.address === contractAddress
  );
  if (inContract) {
    // also flip the asset locked to true as is in contract
    await setAssetLockedTrue(assetId);
    return inContract;
  } else {
    await setAssetLockedFalse(assetId);
    return false;
  }
  // in outputs it must include the contractAddress
};

const getAddressSubmittedBy = (utxos) => {
  // ASSUMPTION THAT ALL INPUTS ARE FROM A SINGLE ADDRESS - EDGE CASE IF NOT
  return utxos.inputs[0].address;
};

const getDatumHashFromUTxOs = (utxos, assetId) => {
  // find the assetId passed in the output, collect that data_hash
  return utxos.outputs[0].data_hash;
};

const checkAssetCached = (policyId, assetId) => {
  console.log(policyId, assetId);
};

const getAssetAddress = (assetId) => {};

// UPDATE WALLETS MARKET ASSET WITH CORRECT INFO
/**
 * @param stakeAddress of the users wallet you want to change
 * @param assetId to verifiy if it is on the account market first, then update it.
 */
export const updateMarketAssets = async (stakeAddress) => {
  let marketData = await getMarketDocs(stakeAddress);

  // struct
  let obj = {};

  for (let i = 0; i < marketData.length; i++) {
    let inContract = await getAssetAddresses(marketData[i].substring(7));
    obj[marketData[i].substring(7)] = {};
    obj[marketData[i].substring(7)]["reference"] = [marketData[i]];
    obj[marketData[i].substring(7)]["isLocked"] = inContract == contractAddress;
  }

  return obj;
};

// MERGE IN CHANGES TO WALLET
export const mergeMarket = async (newMarket, stakeAddress) => {
  try {
    if (newMarket && stakeAddress) {
      // check entire wallet is good.

      const reference = doc(firestore, "wallets", stakeAddress);
      await setDoc(
        reference,
        {
          market: newMarket,
        },
        { merge: true }
      );
      return true;
    }
  } catch (error) {
    console.error(
      `Unexpected error in mergeMarket. [Message: ${error.message}]`
    );
    return false;
  }
};

export const mergeRoyalties = async (policyId, addr, perc) => {
  try {
    if (policyId) {
      const reference = doc(firestore, "collections", policyId);
      await setDoc(
        reference,
        { royalties: { address: addr, percentage: perc * 1000 } },
        { merge: true }
      );
      return;
    }
  } catch (error) {
    console.error(
      `Unexpected error in mergeRoyalties. [Message: ${error.message}]`
    );
    return false;
  }
};

export const checkImageIsCached = async (assetObj) => {
  let policy_id = assetObj.policyId;
  let asset_id = assetObj.asset;

  let requestImageUrl = `https://storage.googleapis.com/freeroam-asset-images/${policy_id}/${asset_id}.webp`;

  const assetImg = await axios(requestImageUrl, {
    method: "GET",
  }).then((response) => {
    console.log(response, " <- IMAGE IS CACHED");
    // image: add the requested image url,
    // isCached: true,
    if (!response.ok) {
      console.log(response.status);
      throw new Error(response.status);
    }
    return response.json();
  });
};

export const getStakeKey = async (address) => {
  return await getStakeAddress(address);
};

export const fetchKPIs = async (period) => {
  // individually get the KPI bois here
  let timeFrame = 86400000 * period; // period is either 1, 7 or 30 (for days)
  let sales = await getRecentTradeActivity(timeFrame);

  // get unique stakeKeys from sales data
  let uniqueStakeKeys = [];
  sales.map((obj) => {
    if (!uniqueStakeKeys.includes(obj.wallet_address)) {
      uniqueStakeKeys.push(obj.wallet_address);
    }
  });

  // get total ADA revenue from sales data.
  let revenue = 0;
  sales.map((obj) => {
    revenue = obj.salePrice ? revenue + Number(obj.salePrice) : revenue + 0;
  });
  // console.log(revenue);
  revenue = revenue / 1000000;

  return [
    {
      category: `${period}D`,
      title: `${sales.length} Total Sales`,
      text: `FreeRoam sales.`,
      image: "https://source.unsplash.com/user/erondu/600x400",
    },
    {
      category: `${period}D`,
      title: `${uniqueStakeKeys.length} Unique Sellers`,
      text: `Unique selling stakekeys on FreeRoam.`,
      image: "https://source.unsplash.com/user/_vickyreyes/600x400",
    },
    {
      category: `${period}D`,
      title: `${revenue.toFixed(2)} ADA Daily Revenue generating ${(
        revenue * 0.02
      ).toFixed(2)}ADA Fees`,
      text: `Revenue from the FreeRoam Marketplace.`,
      image: "https://source.unsplash.com/user/ilyapavlov/600x400",
    },
  ];
};

const getRecentTradeActivity = async (timeFrame) => {
  // 24h ago in ms
  let yesterday = Date.now() - timeFrame;

  let reference = await query(
    collectionGroup(firestore, "purchases"),
    orderBy("submittedPending", "desc"),
    where("submittedPending", ">=", yesterday)
    // use submittedPending where it is above 24 hours ago
  );

  const snapshot = await getDocs(reference);
  const lastVis = snapshot.docs[snapshot.docs.length - 1];
  return snapshot.docs.map((doc) => doc.data());
};
