import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import LogoutButton from "./LogoutButton";

const Header = () => {
  return (
    <HeaderContainer>
      <HeaderContent className="container">
        <Link
          className="header-hoverable"
          style={{ fontSize: "20px", textDecoration: "none" }}
          to="/"
        >
          Home
        </Link>
        <Link
          className="header-hoverable"
          style={{ fontSize: "20px", textDecoration: "none" }}
          to="/collections"
        >
          Collections
        </Link>
        <Link
          className="header-hoverable"
          style={{ fontSize: "20px", textDecoration: "none" }}
          to="/freeroam"
        >
          Market Check
        </Link>
        <Link
          className="header-hoverable"
          style={{ fontSize: "20px", textDecoration: "none" }}
          to="/royalty"
        >
          Royalty
        </Link>
        <a
          className="header-hoverable"
          style={{ fontSize: "20px", textDecoration: "none" }}
          href="https://freeroam.io"
          rel="noopener noreferrer"
          target="_blank"
        >
          Marketplace
        </a>
        <LogoutButton />
      </HeaderContent>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.header`
  background: #f4f4f4;
  height: 64px;
  padding: 0px 2em;
  gap: 20px;
`;

const HeaderContent = styled.header`
  background: #f4f4f4;
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0px 2em;
  gap: 20px;
`;

export default Header;
