import React, { useState, useEffect } from "react";
import "./home.scss";
import { fetchKPIs } from "../../api/index";

const HomePage = () => {
  const [kpis, setKPIs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tf, setTf] = useState(7);

  useEffect(() => {
    setLoading(true);
    fetchKPIs(tf).then((data) => {
      setKPIs(data);
      setLoading(false);
    });
  }, []);

  const changePeriod = (period) => {
    setLoading(true);
    setTf(period);
    setKPIs([]);
    fetchKPIs(period).then((data) => {
      setKPIs(data);
      setLoading(false);
    });
  };

  return (
    <div>
      <h2>Welcome.</h2>
      <br />
      <header className="app-header"></header>
      <Title changePeriod={changePeriod} tf={tf} />

      {loading ? (
        <div>loading...</div>
      ) : (
        <div className="app-card-list" id="app-card-list">
          {Object.keys(kpis).map((key) => (
            <Card key={key} index={key} details={kpis[key]} />
          ))}
        </div>
      )}
    </div>
  );
};

const Title = ({ changePeriod, tf }) => {
  return (
    <section className="app-title">
      <div className="app-title-content">
        <h1>KPIs</h1>
        <div
          style={{ display: "flex", flexDirection: "row", maxWidth: "160px" }}
        >
          <button
            onClick={() => changePeriod(1)}
            className={`tab${tf == 1 ? "-selected" : ""}`}
          >
            1D
          </button>
          <button
            onClick={() => changePeriod(7)}
            className={`tab${tf == 7 ? "-selected" : ""}`}
          >
            7D
          </button>
          <button
            onClick={() => changePeriod(30)}
            className={`tab${tf == 30 ? "-selected" : ""}`}
          >
            30D
          </button>
        </div>
      </div>
    </section>
  );
};

class Button extends React.Component {
  render() {
    return (
      <button className="button button-primary">
        <i className="fa fa-chevron-right"></i> Find out more
      </button>
    );
  }
}

class CardHeader extends React.Component {
  render() {
    const { image, category } = this.props;
    return (
      <header className="card-header">
        <h4 className="card-header--title">{category}</h4>
      </header>
    );
  }
}

class CardBody extends React.Component {
  render() {
    return (
      <div className="card-body">
        <h2>{this.props.title}</h2>
        <p className="body-content">{this.props.text}</p>
      </div>
    );
  }
}

class Card extends React.Component {
  render() {
    return (
      <article className="card">
        <CardHeader
          category={this.props.details.category}
          image={this.props.details.image}
        />
        <CardBody
          title={this.props.details.title}
          text={this.props.details.text}
        />
      </article>
    );
  }
}

export default HomePage;
